import { Flex, Pill, Tooltip } from 'moralis-ui';
import { utils } from '@/helpers/utils';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HomePageCategoryPills.module.scss';

const ThePill = ({ category, text = category }: { category: string; text?: string }) => (
  <Pill color="navy" id={category} size="xs">
    <span title={text === category ? undefined : category}>{text}</span>
  </Pill>
);

export const HomePageCategoryPills = ({ categories }: { categories: string[] }) => {
  if (categories.length === 0) {
    return (
      <div className={styles.tableCellCategoriesList}>
        <ThePill category="Uncategorized" />
      </div>
    );
  }
  const firstCategory = categories[0];
  return (
    <div className={styles.tableCellCategoriesList}>
      <Tooltip
        color="navy"
        size="sm"
        content={<ThePill category={firstCategory} />}
        trigger={<ThePill category={firstCategory} text={utils.format.truncateRemoveLast(firstCategory)} />}
      />

      {categories.length > 1 && (
        <div className={styles.categoriesListMore}>
          <Tooltip
            color="navy"
            size="sm"
            content={
              <Flex direction="column" spacing={1}>
                {categories.slice(1).map((category: string) => (
                  <ThePill category={category} key={category} />
                ))}
              </Flex>
            }
            trigger={
              <Pill color="navy" id="categoryTrigger" size="xs">
                <Flex spacing={2} align="center" direction="row">
                  <FontAwesomeIcon icon={faPlus} height="10px" />
                  {categories.length - 1}
                </Flex>
              </Pill>
            }
          />
        </div>
      )}
    </div>
  );
};
